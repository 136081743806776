import React, { lazy, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import YouTube from "react-youtube";
import Modal from "react-modal";
import ShareModal from "../../ShareModal";

const PodcastsAndVideosHeader = lazy(() => import("../podcastsAndVideoHeader"));

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "800px",
    background: "#1b062f",
    textAlign: "center",
  },
  overlay: {
    background: "rgba(11, 2, 18, 0.75)",
  },
};

const VideoModal = ({ isOpen, onRequestClose, videoId }) => {
  const getVideoOpts = () => {
    const width = window.innerWidth > 640 ? 640 : window.innerWidth * 0.8;
    const height = (width / 16) * 9;
    return {
      height: height.toString(),
      width: width.toString(),
      playerVars: {
        autoplay: 1,
        mute: 1,
      },
    };
  };

  const [opts, setOpts] = useState(getVideoOpts());

  useEffect(() => {
    const handleResize = () => {
      setOpts(getVideoOpts());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const updatePageTitle = () => {
      document.title = "Videos | Cricket8";
    };
    updatePageTitle();
    return () => {};
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="YouTube Video"
      ariaHideApp={false}
    >
      <div className="md:w-full text-right pb-[10px]">
        <button
          onClick={onRequestClose}
          className="modal-close-button text-right"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
            />
          </svg>
        </button>
      </div>
      <div>
        <YouTube videoId={videoId} opts={opts} />
      </div>
    </Modal>
  );
};

export const Videos = () => {
  const [videoList, setVideoList] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const openVideoModal = () => setIsVideoModalOpen(true);
  const closeVideoModal = () => setIsVideoModalOpen(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);
  const videosToFetchCount = 32;
  const [offset, setOffset] = useState(0);

  const goBack = () => (location.href = "/media");

  const xlocation = useLocation();
  const queryParams = new URLSearchParams(xlocation.search);
  let videoId = queryParams.get("videoId");

  function decodeHtmlEntities(str) {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = str;
    return textArea.value;
  }

  const handleLoadMore = () => {
    fetchVideos();
  };

  async function fetchVideos() {
    try {
      const res = await fetch(
        `${process.env.API_URL}/videos/${videosToFetchCount}/${offset}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        const fetchedVideos = [];
        data.videos.forEach((videos) => {
          let title = decodeHtmlEntities(videos.item.snippet.title);
          let media = videos.item.snippet.thumbnails.default.url;
          let bigmedia = videos.item.snippet.thumbnails.high.url;
          const dateObject = new Date(videos.item.snippet.publishedAt);
          let dte = dateObject.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "long",
          });
          let link =
            "https://www.youtube.com/watch?v=" + videos.item.id.videoId;
          fetchedVideos.push({
            title: title,
            media: media,
            bigmedia: bigmedia,
            date: dte,
            link: link,
            videoId: videos.item.id.videoId,
          });
        });

        setVideoList(videoList.concat(fetchedVideos));
        setOffset(offset + fetchedVideos.length);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchVideos();
  }, []);

  useEffect(() => {
    if (videoId !== null) {
      handleVideoClick(videoId);
    }
  }, [videoId]);

  const handleVideoClick = (videoId) => {
    setSelectedVideoId(videoId);
    openVideoModal();
  };

  return (
    <div
      id="content"
      className="min-h-[75vh] mt-[0px] bg-header content-t overflow-hidden"
    >
      <PodcastsAndVideosHeader
        goBack={goBack}
        openShareModal={openShareModal}
        headerTitle={"Videos"}
      />

      <div className="flex flex-col">
        <div className="mt-[30px] w-full grid grid-cols-2 md:flex md:flex-wrap lg:flex lg:flex-wrap">
          {videoList.map((video, index) => (
            <div
              key={index}
              className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-[10px] cursor-pointer"
              onClick={() => handleVideoClick(video.videoId)}
            >
              <div className="w-full  h-[30px] flex flex-row justify-between items-center bg-[#4b3265] text-white text-[14px]">
                <div className="w-[60px] h-[30px] bg-lime text-main flex justify-center items-center">
                  Video
                </div>
                <div className="font-chakra text-[12px] normal-case mr-2">
                  {video.date}
                </div>
              </div>
              <div className="relative">
                <img
                  src={video.bigmedia}
                  className="w-[100%] h-auto object-cover"
                />
                <div
                  className="bg-white h-[40px] w-[40px] text-[12px] flex items-center justify-center text-[#1b062f] pl-[2px]
                              absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="3em"
                    height="3em"
                    viewBox="0 0 24 24"
                  >
                    <path fill="currentColor" d="M8 5.14v14l11-7z" />
                  </svg>
                </div>
              </div>
              <div className="w-full h-[72px] text-left text-white text-[18px] p-[10px] flex">
                <div className="text-[14px] lg:text-[16px]">{video.title}</div>
                {/* <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
              </div> */}
              </div>
            </div>
          ))}
        </div>
        {videoList.length > 0 && (
          <div
            onClick={handleLoadMore}
            className="w-full flex justify-center m-[10px]"
          >
            <div className="mt-4 bg-lime h-[50px] w-[140px] max-w-[430px] text-main flex justify-center items-center">
              LOAD MORE
            </div>
          </div>
        )}
      </div>

      <VideoModal
        isOpen={isVideoModalOpen}
        onRequestClose={() => closeVideoModal()}
        videoId={selectedVideoId}
      />
      <>
        <ShareModal
          isOpen={isShareModalOpen}
          onRequestClose={closeShareModal}
          url={window.location.href}
        />
      </>
    </div>
  );
};
export default Videos;
